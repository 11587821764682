import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import get from 'lodash/get'
import DefaultLayout from '../../layouts/default/defaultLayout'
import style from './BlogPost.module.scss'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withIntl } from '../../i18n'
import { injectIntl } from 'react-intl'
import kebabCase from 'lodash/kebabCase'

class BlogPostTemplate extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
    intl: PropTypes.object,
  }

  textRef = React.createRef()

  checkDot = refNode => {
    const title = refNode.querySelector('h1 > strong')
    if (title && title.innerHTML.endsWith('.')) {
      title.innerHTML = title.innerHTML.substring(0, title.innerHTML.length - 1)
    }
  }

  componentDidMount() {
    if (this.textRef && this.textRef.current) this.checkDot(this.textRef.current)
  }

  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext
    const { intl } = this.props
    // TODO: remove lang check when developing ENG version
    return (
      <DefaultLayout>
        {intl.locale !== 'en' && (
          <div className={style.blog}>
            <div className={classnames(style.innerContainer, style.blogPost)}>
              <Helmet
                meta={[{ charset: 'utf-8' }]}
                title={post.frontmatter.title + ' | ' +  siteTitle}
              />
              <small>{post.frontmatter.date}</small>
              {post.frontmatter.tags && post.frontmatter.tags[0] && (
                <div className={style.blogTags}>
                  {post.frontmatter.tags.map((tag, index) => {
                    return (
                      <Link key={index} to={`/${intl.locale}/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    )
                  })}
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: post.html }} ref={this.textRef} />
              {(previous || next) && (
                <>
                  <hr />
                  <ul className={style.blogControl}>
                    {previous && (
                      <li>
                        <Link to={`/${intl.locale}${previous.fields.slug}`} rel="prev">
                          <small></small> {previous.frontmatter.title} ({previous.frontmatter.date})
                        </Link>
                      </li>
                    )}
                    {next && (
                      <li style={!previous ? { marginLeft: 'auto' } : {}}>
                        <Link to={`/${intl.locale}${next.fields.slug}`} rel="next">
                          {next.frontmatter.title} ({next.frontmatter.date}) <small></small>
                        </Link>
                      </li>
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
      </DefaultLayout>
    )
  }
}

export default withIntl(injectIntl(BlogPostTemplate))

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        tags
      }
    }
  }
`
